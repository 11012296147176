import React from "react"
import styled from "styled-components"
import ServiceTitleFigure from "../../../images/PC/service-title.svg"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"


const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 75px 0px 40px 0px;
`

const Inner = styled.div`
    display: inline-block;
    width: 85%;
    text-align: center;
    min-width: 300px;
`

const Slogan = styled.h2`
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 5px;
`

const Explanations = styled.div`
    margin: 10px 0;
`

const Explanation = styled.p`
    letter-spacing: 0.5px;
    line-height: 18px;
`

const ServiceTitleImg = styled.img`
    display: inline-block;
    width: 80%;
    text-align: center;
    margin-top: 10px;
`

const SloganWide = styled.h2`
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 5px;
`

const ExplanationsWide = styled.div`
    margin: 15px 0;
`

const ServiceTitleImgWide = styled.img`
    width: 400px;
    margin-top: 15px;
`

const MobileServiceTitles = () => {

    const breakPoints = useBreakpoint()

    return (
        <Outer>
            <Inner>
                {breakPoints.smartphone
                    ?
                        <React.Fragment>
                            <Slogan>UPDATE THE INDUSTRY</Slogan>
                            <ServiceTitleImg src={ServiceTitleFigure} alt='ServiceTitle' />
                            <Explanations>
                                <Explanation>地域の産業基盤・地域資源を活かした</Explanation>
                                <Explanation>新しい事業を創造し、世界市場で</Explanation>
                                <Explanation>マーケットリーダーとなるような</Explanation>
                                <Explanation>事業体を生み出す。</Explanation>
                            </Explanations>
                        </React.Fragment>
                    :
                    <React.Fragment>
                            <SloganWide>UPDATE THE INDUSTRY</SloganWide>
                            <ServiceTitleImgWide src={ServiceTitleFigure} alt='ServiceTitle' />
                            <ExplanationsWide>
                                <Explanation>地域の産業基盤・地域資源を活かした新しい事業を創造し、</Explanation>
                                <Explanation>世界市場でマーケットリーダーとなるような事業体を生み出す。</Explanation>
                            </ExplanationsWide>
                        </React.Fragment>
                }
            </Inner>
        </Outer>
    )
}

export default MobileServiceTitles