import React from "react"
import styled from "styled-components"
import ServiceTitleFigure from "../../../images/PC/service-title.svg"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 125px 0px 64px 0px;
`

const Slogan = styled.h2`
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 7px;
`

const ServiceTitleImg = styled.img`
    margin: 20px auto 30px auto;
`

const Explanation = styled.p`
    letter-spacing: 1px;
    text-align: center;
    line-height: 28px;
`

const ServiceTitles = () => {

    return (
        <Outer>
            <Slogan>UPDATE THE INDUSTRY</Slogan>
            <ServiceTitleImg src={ServiceTitleFigure} />
            <Explanation>地域の産業基盤・地域資源を活かした新しい事業を創造し、</Explanation>
            <Explanation>世界市場でマーケットリーダーとなるような事業体を生み出す。</Explanation>
        </Outer>
    )
}

export default ServiceTitles