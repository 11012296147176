import React from "react"
import styled from "styled-components"
import MobileServiceProduct from "../molecules/MobileServiceProduct"

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px 0px 80px 0px;
`

const Title = styled.h2`
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    letter-spacing: 10px;
    text-transform: uppercase;
`

const MobileServiceProducts = ({ productsData }) => {

    return (
        <Root>
            <Title>Products</Title>
            {productsData.map((product, index) => (
                <MobileServiceProduct
                    name={product.name}
                    id={product.id}
                    title={product.title}
                    thumbnailPath={product.thumbnailPath}
                    tags={product.tags}
                    body={product.body}
                    key={index}
                />
            ))}
        </Root>
    )
}

export default MobileServiceProducts
