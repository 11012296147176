import React from "react"
import styled from "styled-components"
import TagLabel from "../atoms/TagLabel"

const Outer = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 900px;
    color: black;
    text-decoration: none;
    margin-bottom: 80px;
`

const ThumbnailImg = styled.img`
    width: 100%;
    height: 455px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.4s filter;
    filter: none;
    &:hover {
        filter: brightness(70%);
    }
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 32px;
`

const Title = styled.h3`
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 1px;
`

const Body = styled.p`
    display: inline-block;
    width: 70%;
    letter-spacing: 1px;
    margin: 24px 0px;
    line-height: 24px;
`

const Tags = styled.div`
    display: flex;
`

const ServiceProduct = ({
    name,
    title,
    thumbnailPath,
    tags,
    body,
}) => {

    return (
        <Outer href={`/products/${name}`}>
            <ThumbnailImg src={thumbnailPath} loading='lazy' />
            <Inner>
                <Title>{title}</Title>
                <Body>{body.slice(0, 100)}...</Body>
                <Tags>
                    {tags.map((tag, index) => (
                        <TagLabel tag={tag} key={index} />
                    ))}
                </Tags>
            </Inner>
        </Outer>
    )
}

export default ServiceProduct
