import React from "react"
import styled from "styled-components"

const Root = styled.p`
    font-size: 14px;
    line-height: 22px;
    padding: 0px 12px;
    border: 1px solid #cccccc;
    border-radius: 20px;
    margin: 4px 0px;
    letter-spacing: 1px;
`

const MobileTagLabel = ({ tag }) => {

    return (
        <Root>{tag}</Root>
    )
}

export default MobileTagLabel