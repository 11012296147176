import React from "react"
import styled from "styled-components"
import ServiceProduct from "../molecules/ServiceProduct"

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px 0px 80px 0px;
`

const Title = styled.h2`
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 50px;
    letter-spacing: 14px;
    text-transform: uppercase;
`

const ServiceProducts = ({ productsData }) => {

    return (
        <Root>
            <Title>Products</Title>
            {productsData.map((product, index) => (
                <ServiceProduct
                    name={product.name}
                    id={product.id}
                    title={product.title}
                    thumbnailPath={product.thumbnailPath}
                    tags={product.tags}
                    body={product.body}
                    key={index}
                />
            ))}
        </Root>
    )
}

export default ServiceProducts
