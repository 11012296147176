import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"
import React from "react"
import styled from "styled-components"
import MobileTagLabel from "../atoms/MobileTagLabel"

const Outer = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    min-width: 280px;
    color: black;
    text-decoration: none;
    margin-bottom: 50px;
`

const ThumbnailImg = styled.img`
    width: 100%;
    height: 180px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.4s filter;
    filter: none;
    &:hover {
        filter: brightness(70%);
    }
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 20px;
`

const Title = styled.h3`
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 1px;
`

const Body = styled.p`
    display: inline-block;
    width: 90%;
    letter-spacing: 1px;
    margin: 16px 0px;
`

const Tags = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const OuterWide = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    color: black;
    text-decoration: none;
    margin-bottom: 50px;
`

const ThumbnailImgWide = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.4s filter;
    filter: none;
    &:hover {
        filter: brightness(70%);
    }
`

const TitleWide = styled.h3`
    font-size: 24px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 1px;
`


const MobileServiceProduct = ({
    name,
    title,
    thumbnailPath,
    tags,
    body,
}) => {

    const breakPoints = useBreakpoint()

    return (
        <React.Fragment>
            {breakPoints.smartphone
                ?
                    <Outer href={`/products/${name}`}>
                        <ThumbnailImg src={thumbnailPath} loading='lazy' />
                        <Inner>
                            <Title>{title}</Title>
                            <Body>{body.slice(0, 100)}...</Body>
                            <Tags>
                                {tags.map((tag, index) => (
                                    <MobileTagLabel tag={tag} key={index} />
                                ))}
                            </Tags>
                        </Inner>
                    </Outer>
                :
                    null
            }
            {breakPoints.mobileWide
                ?
                    <OuterWide href={`/products/${name}`}>
                        <ThumbnailImgWide src={thumbnailPath} loading='lazy' />
                        <Inner>
                            <TitleWide>{title}</TitleWide>
                            <Body>{body.slice(0, 100)}...</Body>
                            <Tags>
                                {tags.map((tag, index) => (
                                    <MobileTagLabel tag={tag} key={index} />
                                ))}
                            </Tags>
                        </Inner>
                    </OuterWide>
                :
                    null
            }
        </React.Fragment>
    )
}

export default MobileServiceProduct
