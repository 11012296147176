import React from "react"
import styled from "styled-components"

const Root = styled.p`
    font-size: 16px;
    line-height: 28px;
    padding: 0px 16px;
    border: 1px solid #cccccc;
    border-radius: 20px;
    margin: 0px 6px;
    letter-spacing: 1px;
`

const TagLabel = ({ tag }) => {

    return (
        <Root>{tag}</Root>
    )
}

export default TagLabel