import React from "react"
import SEO from "../seo"
import Hero from "../PC/organisms/Hero"
import Header from "../PC/organisms/Header"
import Footer from "../PC/organisms/Footer"
import ServiceTitles from "../PC/organisms/ServiceTitles"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import MobileServiceTitles from "../mobile/organisms/MobileServiceTitles"
import ServiceProducts from "../PC/organisms/ServiceProducts"
import MobileServiceProducts from "../mobile/organisms/MobileServiceProducts"
import judgePage from "../judgePage"
import MobileHeader from "../mobile/organisms/MobileHeader"
import MobileFooter from "../mobile/organisms/MobileFooter"
import MobileHero from "../mobile/organisms/MobileHero"

const Template = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    height: auto;
    width: 100%;
`

const ServiceSEOData = {
    title: 'サービス',
    lang: 'jp',
    url: '/service',
    description:'UPDATE THE INDUSTRY 未来産業創造事業',
}

const ServiceTemplate = ({ productsData }) => {

    const page = judgePage('SERVICE')
    const breakPoints = useBreakpoint()

    return (
        <Template>
            <SEO
                title={ServiceSEOData.title}
                lang={ServiceSEOData.lang}
                url={ServiceSEOData.url}
                description={ServiceSEOData.description}
            />
            {breakPoints.pc
                ?
                    <React.Fragment>
                        <Hero page={page.name} />
                        <ServiceTitles />
                        <ServiceProducts productsData={productsData}  />
                        <Footer />
                        <Header page={page.name} />
                    </React.Fragment>
                :
                    null
            }
            {breakPoints.mobile
                ?
                    <React.Fragment>
                        <MobileHero page={page.name} />
                        <MobileServiceTitles />
                        <MobileServiceProducts productsData={productsData} />
                        <MobileFooter />
                        <MobileHeader />
                    </React.Fragment>
                :
                    null
            }
        </Template>
    )
}

export default ServiceTemplate