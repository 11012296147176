import React from "react"
import ServiceTemplate from "../components/templates/ServiceTemplate"
import "../layouts/style.css"

const ServicePage = ({ data }) => {
  const productPosts = []
  const projectPosts = []

  data.allMarkdownRemark.edges.forEach((post) => {
    const frontmatter = post.node.frontmatter
    const body = post.node.excerpt
    const slug = frontmatter.slug.match(/([a-z]{7})(-)([0-9]*)/)
    const postData = {
      name: frontmatter.slug,
      id: (slug === null) ? -1 : slug[3],
      title: frontmatter.title,
      thumbnailPath: frontmatter.thumbnail,
      client: frontmatter.client,
      tags: frontmatter.tags,
      body: body
    }
    if (slug !== null) {
      if (postData.client === null) {
        productPosts.push(postData)
      } else {
        projectPosts.push(postData)
      }
    }
  })
  productPosts.sort((a, b) => {
    if (a.id <= b.id) {
      return 1
    } else {
      return -1
    }
  })
  projectPosts.sort((a, b) => {
    if (a.id <= b.id) {
      return 1
    } else {
      return -1
    }
  })

  return (
    <ServiceTemplate productsData={productPosts} />
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            title
            thumbnail
            client
            tags
          }
          excerpt(pruneLength: 1000)
        }
      }
    }
  }
`

export default ServicePage